export const BranchStates = [
  {
    key: '1',
    value: 'Delhi',
    label: 'Delhi',
  },
  {
    key: '2',
    value: 'Uttar Pradesh',
    label: 'Uttar Pradesh',
  },
  {
    key: '3',
    value: 'Haryana',
    label: 'Haryana',
  },
  {
    key: '4',
    value: 'West Bengal',
    label: 'West Bengal',
  },
  {
    key: '5',
    value: 'Andhra Pradesh',
    label: 'Andhra Pradesh',
  },
  {
    key: '6',
    value: 'Maharashtra',
    label: 'Maharashtra',
  },
  {
    key: '7',
    value: 'Karnataka',
    label: 'Karnataka',
  },
  {
    key: '8',
    value: 'Tamil Nadu',
    label: 'Tamil Nadu',
  },
  {
    key: '9',
    value: 'Telangana',
    label: 'Telangana',
  },
  {
    key: '10',
    value: 'Gujrat',
    label: 'Gujrat',
  },
  {
    key: '11',
    value: 'Madhya Pradesh',
    label: 'Madhya Pradesh',
  },
];

export const BranchCities = [
  {
    key: '1',
    value: 'Delhi',
    label: 'Delhi',
    state: 'Delhi',
  },
  {
    key: '2',
    value: 'Noida',
    label: 'Noida',
    state: 'Uttar Pradesh',
  },
  {
    key: '3',
    value: 'Ghaziabad',
    label: 'Ghaziabad',
    state: 'Uttar Pradesh',
  },
  {
    key: '4',
    value: 'Gurgaon',
    label: 'Gurgaon',
    state: 'Haryana',
  },
  {
    key: '5',
    value: 'Faridabad',
    label: 'Faridabad',
    state: 'Haryana',
  },
  {
    key: '6',
    value: 'Kolkata',
    label: 'Kolkata',
    state: 'West Bengal',
  },
  {
    key: '7',
    value: 'Hyderabad',
    label: 'Hyderabad (TS)',
    state: 'Telangana',
  },
  {
    key: '8',
    value: 'Hyderabad',
    label: 'Hyderabad (AP)',
    state: 'Andhra Pradesh',
  },
  {
    key: '9',
    value: 'Mumbai',
    label: 'Mumbai',
    state: 'Maharashtra',
  },
  {
    key: '10',
    value: 'Bangalore',
    label: 'Bangalore',
    state: 'Karnataka',
  },
  {
    key: '11',
    value: 'Chennai',
    label: 'Chennai',
    state: 'Tamil Nadu',
  },
  {
    key: '12',
    value: 'Pune',
    label: 'Pune',
    state: 'Maharashtra',
  },
  {
    key: '13',
    value: 'Ahmedabad',
    label: 'Ahmedabad',
    state: 'Gujarat',
  },
  {
    key: '14',
    value: 'Indore',
    label: 'Indore',
    state: 'Madhya Pradesh',
  },
];
